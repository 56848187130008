/* eslint-disable @next/next/no-img-element */

import React, { useCallback, useEffect, useRef, useState } from 'react';
import Head from 'next/head';
import Link from 'next/link';

import useMobile from '../../Hooks/Mobile';
import useScrolledTo from '../../Hooks/ScrolledTo';
import SwipingPanels from '../../SwipingPanels';
import { dynamicEvent } from '../../../lib/gtag';

import styles from './styles.module.scss'
import BankOfIrelandLogo from '../../../public/assets/images/boi-service.png'
import AXALogo from '../../../public/assets/images/axa-service-logo.png'
import BestDriveLogo from '../../../public/assets/images/bg-logo.svg'
import { useIsBoiWebsite } from "../../Context/WebsiteType";

const content = [
  {
    title: 'Bank of Ireland',
    text: 'Bank of Ireland Finance has a range of finance options to get you motoring in your electric car today. We work with 22 motor brands in the Irish market today offering Hire Purchase (HP) and Personal Contract Plans (PCP) allowing customers to spread the cost of their electric vehicle in a way that suits their needs.',
    link: '/partner/bank-of-ireland',
    image: BankOfIrelandLogo.src
  },
  {
    title: 'AXA Ireland',
    text: 'AXA is one of the leading motor insurers in Ireland offering great cover for conventional, electric and hybrid vehicles. <b>Get an online quote and receive up to 10% off!</b>',
    link: '/partner/insurance',
    image: AXALogo.src
  },
  {
    title: 'BestDrive by Continental',
    text: 'How often should you be servicing your EV? Electric vehicle servicing is essential for the performance and reliability of your car, to guarantee you peace of mind on the road.',
    link: '/partner/best-drive',
    image: BestDriveLogo.src
  }
]

export default function Services()  {
  const [slide, setSlide] = useState(0)
  const sectionRef = useRef<HTMLDivElement>(null)

  const tablet = useMobile(900);

  const startSlideShow = useScrolledTo(sectionRef)

  const isBoiWebsite = useIsBoiWebsite();

  const handleNextSlide = useCallback(() => {
    if (slide < content.length - 1) {
      setSlide(slide + 1)
    }
    else setSlide(0)
  }, [slide])
  
  // 12 seconds slide animation 
  useEffect(() => {
    if (startSlideShow) {
      const timeout = setTimeout(handleNextSlide, 12000)
      return () => clearTimeout(timeout)
    }
  }, [startSlideShow, handleNextSlide])

  const onSlideClick = (slideNumber: number) => {
    if (slide !== slideNumber) setSlide(slideNumber)
  }

  const trackBenefit = (title: string) => {
    dynamicEvent("Home EV Benefits", `Clicked on ${title}`)
  }

  const renderSlide = (s: number) => {
    return (
      <div key={s} className={styles.slide}>
        <h2 className={`${styles.sHeader} big-header`}>{content[s].title}</h2>

        { tablet &&
          <img 
            key={s}
            className={styles.image}
            src={content[s].image}
            alt={content[s].title}
          />
        }
        <p 
          className={`${styles.sText} big-text`} 
          dangerouslySetInnerHTML={{ __html: content[s].text }}
        />
        <Link href={content[s].link} passHref>
          <button className={`${styles.findBtn} ${isBoiWebsite ? 'dark-blue-button-boi' : 'blue-button'}`} onClick={() => trackBenefit(content[s].title)}>
            Find out More
          </button>
        </Link>
      </div>
    )
  }
 
  return (
    <>
      <Head>
        {content.map(({ image }) => {
          return <link key={image} rel="preload" href={image} as="image" />
        })}
      </Head>

      <div className={styles.presentation} ref={sectionRef}>

        { !tablet ? <>
            <div className={styles.contentControls}>
              { content.map((item, index) => (
                <div key={index} className={styles.logoHolder} onClick={() => {onSlideClick(index)}}>
                  <img 
                    className={`${styles.companyLogo} ${slide === index ? styles.active : ''}`} 
                    src={item.image} 
                    alt={item.title} 
                  />
                </div>
              ))}
            </div>

            <div className={styles.content}>
              <img 
                key={slide}
                className={styles.image}
                src={content[slide].image}
                alt={content[slide].title}
              />

              <div className={styles.contentText}>
                { renderSlide(slide) }
              </div>
            </div>
          </>
        : 
        (
          <SwipingPanels panel={slide} setPanel={setSlide}>
            {content.map((item, index) => (
              <div key={index} className={styles.content}>
                <div className={styles.contentText}>
                  { renderSlide(index) }
                </div>
              </div>
            ))}
          </SwipingPanels>
        )}
      </div>
    </>
  )
};
